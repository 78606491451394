import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import GoToTop from '../components/ui/GoToTop'
import PublicationEntries from '../components/Publications'

const Publications = (props) => {         
    return (
    <Layout>
        <Helmet>
            <title>Publications - Jonathan Camargo</title>
            <meta name="description" content="Camargo et. al. Publications" />
        </Helmet>

        <div id="main" className="alt">

            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Publications</h1>
                    </header>

                    <h2 id="content">Journal Articles</h2>                    

                    <PublicationEntries type="journals">
                    </PublicationEntries>
                    <GoToTop></GoToTop>

                    <hr className="major" />

                    <h2 id="elements">Conference Proceedings</h2>
                    
                    <PublicationEntries type="conferences">
                    </PublicationEntries>
                                        
                    <GoToTop></GoToTop>

                    <hr className="major" />

                    <h2 id="elements">Book Chapters and Theses</h2>

                    <PublicationEntries type="books">
                    </PublicationEntries>
                    <GoToTop></GoToTop>

                    <hr className="major" />

                    <h2 id="elements">Advised Theses</h2> 

                    <PublicationEntries type="theses">
                    </PublicationEntries>
                    <GoToTop></GoToTop>                    
                    
                    </div>
                
            </section>

        </div>

    </Layout>
)
    }

export default Publications
